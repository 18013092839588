.pagination {
  padding: 8px 0;
  margin: 0;
  li {
    margin: 0 2px;
  }
  li a {
    padding: 0;
    color: $color-secondary;
    font-weight: bold;
    width: 18px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px;
    margin: 0;
    border: none;
    font-size: 12px;
    cursor: pointer;
  }
  .disabled a {
    background: transparent;
    cursor: default;
  }
  & > .btn-prev-page a,
  & > .btn-next-page a,
  & > .btn-first-page a,
  & > .btn-last-page a {
    width: auto;
    padding: 0 8px;
  }

  & > .btn-numbered-page {
    width: 28px;
    height: 28px;
    text-align: center;
    &.active {
      background: $color-secondary;
      font-weight: 600;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      text-align: center;
      a {
        width: 28px;
        height: 28px;
        color: $white !important;
      }
    }
  }
}
