.login-container {
  width: 100%;
  height: 100vh;
  background-image: url("../../img/login-bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
	align-items: center;
  justify-content: center;
  background-size: cover;

  .login-form {
    max-width: 570px;
    width: 100%;
		margin:	auto;
    background: $white;
    border-radius: 8px;
    padding: 80px 105px 65px;
    text-align: center;

    .section-logo {
      height: 105px;
      margin-bottom: 60px;
    }
    .section-title {
      h1 {
        font-size: 28px;
        font-weight: bold;
        color: #464646;
        margin-bottom: 8px;
      }
      .subtitle {
        font-size: 14px;
        color: #989898;
        margin-bottom: 16px;
      }
    }
    .btn-login {
      width: 100%;
      padding: 16px;
      font-size: 14px;
      margin-top: 50px;
    }
  }
}