.detail-tabs {
	border-bottom: 1px solid $silver;
	margin-bottom: 24px;
	margin-top: 0;

	.nav-link {
		font-size: 12px;
		font-weight: 700;
		line-height: 16px;
		padding: 8px 16px;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		border-bottom: 2px solid transparent;
		outline: none;
		box-shadow: none;
		&:hover {
			border-top: 0;
			border-right: 0;
			border-left: 0;
			border-bottom: 2px solid $cloudly-blue;
			color: $cloudly-blue;
		}
		&.active {
			border-top: 0;
			border-right: 0;
			border-left: 0;
			border-bottom: 2px solid $black;
		}
	}
}