.table {
	& > .table-header {
		tr > th {
				background-color: $white-smoke;
			  font-size: 12px;
  			font-weight: 700;
				color: $color-note;
				padding: 14px 16px;
		}
		tr {
			& :first-child.col-header {
				padding-left: 40px;
			}
			& :last-child.col-header {
				padding-right: 40px;
			}
		}
	}

	& > .table-body {
		tr > td {
			padding: 16px;
			font-size: 12px;
			.text {
  			font-weight: 500;
				line-height: 30px;
				margin-bottom: 0;
			}
			.user-photo {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				overflow: hidden;

				& > .thumbnail {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		tr {
			& :first-child.col-item {
				padding-left: 40px;
			}
			& :last-child.col-item {
				padding-right: 40px;
			}
		}
	}
}