.shortcut-key {
	border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $alto;
  background-color: $white;
	font-family: 'Helvetica Neue';
  font-size: 10px;
  font-weight: bold;
	line-height: 12px;
	color: $color-primary;
	padding: 4px;
}