.action-button {
	.btn {
		padding: 8px 10px;
		.text {
			font-size: 12px;
			line-height: 22px;
			font-weight: 700;
			margin-right: 36px;
		}
	}
}

.btn {
	padding: 12px 24px;
	font-weight: 600;
	line-height: 14px;
	border: none;
	&.btn-pills {
		border-radius: 50px;
		outline: none;
		box-shadow: none;
	}

	&.btn-disabled {
		color: $white;
		background-color: $gray-500;
	}

	&.btn-primary {
		color: $white;
		background-color: $color-primary;
	}
	&.btn-secondary {
		color: $white;
		background-color: $color-secondary;
	}

	&.btn-primary-plain {
		color: $color-primary;
		background: transparent;
		&:hover, &:focus {
			color: $white;
			background-color: $color-primary;
		}
	}
	&.btn-secondary-plain {
		color: $color-secondary;
		background: transparent;
		&:hover, &:focus {
			color: $white;
			background-color: $color-secondary;
		}
	}

	&.btn-primary-light {
		color: $color-primary;
		background-color: rgba($color-primary, .1);
	}
	&.btn-secondary-light {
		color: $color-secondary;
		background-color: rgba($color-secondary, .1);
	}
}
.btn-blank {
	background: none;
  border: none;
	outline: none;
	font-weight: 700;
  &:hover,
  &:focus {
    outline: none;
  }
}
.btn-checkbox {
	padding: 0px;
	color: $color-primary;
	background: none;
	font-size: 18px;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
  }
}
.btn-radio-pair {
	padding: 0px;
	background: none;
  border: none;
	outline: none;
	color: $black;
	cursor: pointer;
  &:hover,
  &:focus {
		outline: none;
	}
	i {
		font-size: 18px;
		color: $color-primary;
		margin-right: 10px;
	}
}

.btn-see-more {
	width: 100%;
	font-weight: 700;
	color: $gray-500;
	background: transparent;
	border: none;
	font-size: 14px;
	padding: 18px;
	transition: .3s ease-in-out;
	&:hover {
		background: $gray-200;
	}
}
// .btn-text-icon {
// 	border-radius: 50%;
// 	border: none;
// 	padding: 0px;
// 	font-size: 14px;
// 	span {
// 		margin-right: 6px;
// 	}
// 	i {
// 		line-height: 0px;
// 		font-size: 14px;
// 	}
// 	&.danger {
// 		background-color: transparent;
// 		color: $color-danger;
// 	}
// }