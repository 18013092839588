.side-bar-container {
	padding: 46px 35px 46px 32px;
	.sidebar {
		.sidebar-brand {
			height: 40px;
			margin-bottom: 45px;
		}
		width: 235px;
		height: 100%;
		flex:0 0 240px;
		// box-shadow: 0 4px 10px 0 rgba($black, 0.05);
	}

}