.menu-item {
	display: flex;
	align-items: center;
	flex-direction: row;

	.icon-menu {
		margin-right: 8px;
	}

	.text {
  	font-size: 13px;
		line-height: 20px;
		color: $gray-500;
	}
	&.active {
		background: $color-primary;
		border-radius: 25px;
		.icon-menu {
			fill: $white;
		}
		.text {
			color: $white;
		}
	}
}