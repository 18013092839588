.icon {
	width: 24px;
	height: 24px;
	fill: $white;

	&.arrow-expand {
		width: 24px;
		height: 24px;
		fill: $color-blue-gray;
		transition: 500ms ease all;
		&.rotate {
			transform: rotate(180deg);
		}
	}

	&.menu-ellipsis {
		@extend .icon-menu;
	}

	&.search {
		width: 16px;
		height: 16px;
		fill: $silver;
	}
}

.icon-menu {
	width: 20px;
	height: 20px;
	fill: $silver;
}

.icon-full-color {
	width: 24px;
	height: 24px;
}