.container-card {
	border-radius: 30px;
	background-color: $white;
	padding: 28px;
}

.card-dashboard {
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 8px;
	padding: 18px 16px;
	.count {
		font-size: 24px;
		font-weight: bold;
	}
	.description {
		font-size: 12px;
		color: $dusk;
		margin: 0px;
	}
	.image-holder {
		position: absolute;
		top: 0px;
		right: 16px;
		height: 100%;
		display: flex;
		align-items: center;
		img {
			height: 55%;
		}
	}
}