@viewport { width: device-width; }

html,body {
  height: 100%;
}

html {
  background-color: $color-background;
  box-sizing: border-box;
  height: 100%;
}

* {
  &:before,
  &:after { box-sizing: inherit; }
}

body {
  -webkit-font-smoothing: antialiased;
  color: $color-text;
  font-family: $font-family-default;
  background-color: $color-background;
  min-height: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

a {
  background: transparent;
  color: $color-link;
  text-decoration: none;
  transition: color .2s;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-display;
  font-weight: normal;
}
