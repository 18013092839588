.main-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	background-color: #efefef;
	// padding: 46px 32px;
}

.main-content {
	padding: 46px 32px 46px 0px;
	height: 100vh;
	// padding-left: 35px;
  overflow-y: scroll;
}