.modal-backdrop {
	background: #111111;
	opacity: 0.6;
}
.modal {
	.btn-close {
		background-color: $color-primary;
		border-radius: 50%;
		color: $white;
		border: none;
		padding: 0px;
		height: 23px;
		width: 23px;
		i {
			line-height: 0px;
			font-size: 14px;
		}
	}
	.modal-content {
		border-radius: 30px;
	}
	.modal-header {
		background: transparent;
		border-bottom: 1px solid transparent;
		padding: 28px 31px 8px;
		.modal-title {
			font-size: 18px;
			line-height: 20px;
			// font-weight: 600;
		}
	}
	.modal-body {
		padding: 16px 32px 24px;
	}
	.modal-dialog {
		margin: 50px auto;
		width: 100%;
		border-radius: 50px;
	}
	&.modal-large{
		align-items: center;
    .modal-dialog{
			max-width: 1070px !important;
    }
  }
  &.modal-medium{
		align-items: center;
    .modal-dialog{
			max-width: 50% !important;
    }
  }
  &.modal-small{
		align-items: center;
    .modal-dialog{
      max-width: 600px;
    	margin: 5rem auto !important;
    }
  }
}
