.total-count {
	.text {
		font-size: 12px;
  	font-weight: 500;
		line-height: 32px;
		vertical-align: middle;
	}
	.value {
		font-size: 20px;
		font-weight: 700;
		line-height: 32px;
		vertical-align: middle;
	}
}