.menu-sub-item {
	display: flex;
	align-items: center;
  flex-direction: row;
	.text {
  	font-size: 13px;
		line-height: 20px;
    color: $silver;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    border-color: $silver;
    border-left: solid;
    border-width: 2px;
  }
	&.active {
		.icon-menu {
			fill: $white;
		}
		.text {
      color: $color-primary;
		}
	}
}