	.main-details {
		.user-photo {
			width: 70px;
			height: 70px;
			overflow: hidden;
			border-radius: 50%;
			.thumbnail {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.details {
			margin-top: 8px;
			.full-name,
			.product-name {
        min-height: 24px;
				font-size: 20px;
				font-weight: 900;
				line-height: 20px;
				color: $blue-whale;
				margin-bottom: 8px;
			}
			.institute,
			.user-type,
			.price {
				font-size: 12px;
  			font-weight: 900;
				line-height: 20px;
				margin: 0;
			}

			.price {
				color: $color-success;
			}
		}
	}