.box-fit-wrapper {
  position: relative;
  .box-fit-overflow {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0;
    left: 0;
    overflow: auto;
  }
}

.dropdown-toggle > * {
  pointer-events: none; // make sure that the anchor is clicked instead of child elements
}

.with-arrow {
  position: relative;
  &::before,
  &::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }
  &::after {
    border-color: transparent;
    border-left-color: #fff;
    border-width: 15px;
    margin-top: -15px;
  }
  &::before {
    border-color: transparent;
    border-left-color: #dee2e6;
    // border-left-color: $border-color; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
    border-width: 16px;
    margin-top: -16px;
  }
}

// ::-webkit-scrollbar-track
// {
//   background-color: #e6e5e1;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar
// {
// 	width: 6px;
//   background-color: #e6e5e1;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb
// {
//   background-color: #adb5bd;
//   // background-color: $gray-500; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
//   border-radius: 10px;
// }

.detail-header-box {
  min-height: 144px;
  .detail-header-photo {
    position: relative;
    margin-bottom: -25px;
    min-height: 128px;
    max-height: 128px;
    min-width: 128px;
  }
}

.detail-tabs {
  margin-top: -5px;
  & :first-child.nav-item {
    margin-left: 15px;
  }
}

.embed-responsive-ultra-super-wide::before {
  padding-top: 20%;
}

.caret-off::before {
    display: none;
}
.caret-off::after {
    display: none;
}

.slider-arrow-next {
  position: absolute;
  right: 8px;
  top: calc(50% - 17px);
  z-index: 2;
}

.slider-arrow-prev {
  position: absolute;
  left: 8px;
  top: calc(50% - 17px);
  z-index: 2;
}