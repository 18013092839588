.sidebar-account {
	padding: 16px;
	.photo {
		margin-right: 8px;
		& > .thumbnail {
			width: 40px;
			height: 40px;
			object-fit: cover;
		}
	}
	.details {
		.user-name {
			font-size: 14px;
			font-weight: 700;
			line-height: 16px;
			margin: 0;
		}
		.circle {
			width: 10px;
			height: 10px;
			vertical-align: middle;
			margin: 0;
		}
		.text-logout {
			font-size: 10px;
			line-height: 10px;
			margin: 0;
			font-weight: 700;
		}
	}
}