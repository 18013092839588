.floating-form {
	border: 1px solid $alto;
	border-radius: 4px;
	background: $white;
	padding: 6px 8px;
	position: relative;
	&.required {
		border: 1px solid $color-danger;
	}
	&.is-focus {
		border: 1px solid $color-primary;
	}
	.required {
		position: absolute;
		top: 4px;
		right: 8px;
		font-size: 8px;
		line-height: 12px;
		color: $color-danger;
	}
}

.floating {
  transition: background-color 0.2s ease;
	outline: none;

	&:hover {
		.floating-input,
		.floating-textarea {
			border-color: $silver;
		}
	}

	&:hover,
	&:focus-within {
  	background-color: $white;
	}
}

.floating-input {
  padding: 12px 8px 0;
  font-size: 16px;
  font-weight: 700;
	line-height: 32px;
	outline: none;

	&.form-control {
		height: 34px;
		box-shadow: none;
		border: 0;
		border-radius: 0;
	}

	&::placeholder {
		color: rgba(0, 0, 0, 0);
	}

	&:focus + .floating-label::after {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}

	&:placeholder-shown + .floating-label::before {
		transform: translate3d(0, -4px, 0) scale3d(1, 1, 1);
		line-height: 32px;
	}

	&:focus + .floating-label::before {
		color: $dove-gray;
	}

	&.textarea {
	&.form-control {
		min-height: 200px !important;
		height: auto !important;
	}
}
}

.floating-label {
  display: block;
  position: absolute;
	top: 12px;
	left: 8px;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
	margin: 0;


	&::before {
		color: $silver;
		font-weight: bold;
		content: attr(data-content);
		display: inline-block;
		filter: blur(0);
		backface-visibility: hidden;
		transform-origin: left top;
		transition: transform 0.2s ease;
		left: 0px;
		position: relative;
	}

	// &::after {
	// 	bottom: 1rem;
	// 	content: "";
	// 	height: 0.1rem;
	// 	position: absolute;
	// 	transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
	// 	opacity: 0;
	// 	left: 0;
	// 	top: 100%;
	// 	margin-top: -0.1rem;
	// 	transform: scale3d(0, 1, 1);
	// 	width: 100%;
	// 	// background-color: $dove-gray;
	// }
}

.floating-label::before,
.floating-input:focus + .floating-label::before {
  transform: translate3d(0, -12px, 0) scale3d(0.6, 0.6, 1);
	line-height: 12px;
}

.hidden.-visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}