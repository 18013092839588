.main-header {
	padding: 24px 0;
	.navbar {
		padding: 0 16px;
	}
	.page-title {
		.title {
			font-size: 16px;
			font-weight: 900;
			margin: 0;
		}
	}
}