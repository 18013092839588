.listing-section {
	background: $white;
	height: 100%;
	padding: 0;
	.listing-header {
		border-bottom: 1px solid $alto;
	}
	.listing-title,
	.filter-by,
	.listing-action {
		padding: 16px;
	}
	.search-filter {
		padding: 0 16px;
	}

	.listing-action {
		border-top: 1px solid $alto;
	}
}