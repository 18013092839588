.csv-instruction-container {
  .image-holder {
    height: 102px;
    margin-bottom: 22px;
    text-align: center;
  }
  .mid-column {
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
  }
  .section-column {
    padding: 55px;
  }
  .section-title {
    padding: 0px 17%;
    text-align: center;
    label {
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
    }
  }
}