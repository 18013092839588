// .section-title {
// 	padding: 16px 16px 0;
// 	& > .title {
// 		font-size: 16px;
//   	font-weight: 700;
// 		margin-bottom: 0;
// 	}
// }

.section-subtitle {
	& > .subtitle {
		font-size: 10px;
		font-weight: 700;
		line-height: 24px;
		color: $black;
		margin: 0;
	}
}

.listing-title {
	& > .title {
		font-size: 12px;
		font-weight: 700;
		line-height: 24px;
		color: $black;
		margin: 0;
	}
}