.generic-listing {
	.genericlist-text {
		font-size: 500;
		margin-bottom: 0;
		font-size: .8rem;
	}

	.genericlist-list {
		position: relative;
		overflow-y: scroll;
		display: block;
		height: 100%;
		background: $athens-gray;
		& > .genericlist-item {
			padding: 16px;
			cursor: pointer;
			margin-bottom: 8px;
			background: $white;
			&.active {
				background: rgba($pigeon-post, 0.3);
			}

			&:last-child {
				margin: 0;
			}
		}
	}
}

.genericlist-item {
	.photo {
		& > .thumbnail {
			width: 30px;
			height: 30px;
			object-fit: cover;
		}
	}
	.avatar-initial {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		border-radius: 50%;
		background: $color-primary;
		& > .initial {
			color: $white;
			font-size: 16px;
			line-height: 10px;
		}
	}
	.details {
		flex-grow: 1;
		.main-text {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			margin: 0;
		}
	}

	.status {
		.tooltip {
			color: $white;
			background-color: $black;
			&.place-right {
				&:after {
				border-right-color: $black;
				border-right-style: solid;
				border-right-width: 6px;
				}
			}
		}
	}
}

.custom-tooltip {
	font-size: 14px;
  font-weight: 900;
	line-height: 20px;
	&:hover {
		visibility: visible;
		opacity: 1;
	}
}