.access-page {
	height: 100vh;
	width: 100%;
	overflow: hidden;
	background: url('../../img/bg-access.svg') no-repeat center center;
	background-size: cover;
	position: relative;

	.login-section {
		margin-top: 100px;

		.login-title {
			margin-bottom: 40px;
			& > .title {
				font-size: 30px;
  			font-weight: 700;
				line-height: 42px;
			}
			& > .description {
				font-size: 14px;
  			font-weight: 500;
				line-height: 24px;
			}
		}

		.login-form {
			& > .action{
				.btn {
					font-size: 14px;
  				font-weight: 700;
					line-height: 24px;
					color: $white;
					padding: 12px 16px;
					box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
					transition: 500ms ease all;
					&:hover {
						transform: translateY(-3px);
						box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.2);
					}
				}
			}
		}

	}
}

.wave-details	{
	width:100%;
	position:absolute;
	bottom:0;
	left:0;
	.wave {
		background: url(../../img/waves.svg) repeat-x;
		position: absolute;
		top: -198px;
		width: 6400px;
		height: 198px;
		animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
		transform: translate3d(0, 0, 0);
	}

	.wave:nth-of-type(2) {
		top: -175px;
		animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -2s infinite, swell 7s ease -1.25s infinite;
		opacity: 0.1;
	}

		.wave:nth-of-type(3) {
		top: -175px;
		animation: wave 10s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -3s infinite, swell 7s ease -1.25s infinite;
		opacity: 0.1;
	}
}