.circle {
	width: 14px;
	height: 14px;
	background: $alto;
	border-radius: 50%;
	&.with-icon {
		vertical-align: middle;
		width: 20px;
		height: 20px;
		.fas,
		.icon {
			font-size: 10px;
			line-height: 20px;
			width: 20px;
			vertical-align: top;
			color: $white;
			text-align: center;
		}
	}
	&.for-verification {
		background: $blue-whale;
	}
	&.success {
		background: $color-success;
	}
	&.rejected {
		background: $color-danger;
	}
	&.others {
		background: $regent-grey;
	}
}