// Color Palette
// examples based on the official Sass styleguide

// Gray
$shark: #292e34;
$outer-space: #393f44;
$abbey: #4d5258;
$rolling-stone: #72767b;
$oslo-gray: #8b8d8f;
$silver: #bababa;
$alto: #d1d1d1;
$gallery: #ededed;
$wild-sand: #f5f5f5;
$alabaster: #fafafa;
// =============

// Shades of Gray
$gray-050: $alabaster;
$gray-100: $wild-sand;
$gray-200: $gallery;
$gray-300: $alto;
$gray-400: $silver;
$gray-500: $oslo-gray;
$gray-600: $rolling-stone;
$gray-700: $abbey;
$gray-800: $outer-space;
$gray-900: $shark;
// =============

// descriptive colors
$hopbush:                   #c69;
$bouquet:                   #b37399;
$venus:                     #998099;
$patina:                    #699;
$nebula:                    #d2e1dd;
$white:                     #fff;
$dawn-pink:                 #f2ece4;
$wafer:                     #e1d7d2;
$iron:                      #dadbdf;
$regent-grey:               #808c99;
$pale-sky:                  #6b717f;
$midnight-blue:             #036;
$black:                     #4D4F5C;

$deeppink: #ff0373;
$tomato: #ff662a;
$white-smoke: #f5f6fa;
$dusk: #505a7d;
$cloudly-blue: #b0b8da;
$blue-whale: #032c4b;
$pigeon-post: #afb8da;
$orangey-red: #ff3636;
$deep-sea: #07817d;
$boring-green: #68ad63;
$dark-grey: #282829;
$slate-grey: #6a6b6e;
$bluey-grey: #9e9fa5;
$light-periwinkle: #d4dae1;
$very-light-pink: #bbbbbb;
$white: #ffffff;
$alto: #e0dfdf;
$dove-gray: #6f6f6f;
$silver: #bababa;
$athens-gray: #f9f9fb;
// =============

// main color palette
// =============
$color-primary:             $deeppink;
$color-secondary:           $tomato;
$color-blue-gray:           $dusk;
$color-shadow:              rgba($black, .15);
$color-note:                #A3A6B4;

// Common colors
$color-background:          $athens-gray;
$color-background-shade:    $iron;
$color-background-invert:   $hopbush;
$color-text:                $black;
$color-text-weak:           $regent-grey;
$color-text-strong:         $midnight-blue;
$color-text-heading:        $regent-grey;
$color-text-invert:         rgba($color-background, .75);
$color-text-strong-invert:  $color-background;

// Links
$color-link:                $color-primary;
$color-link-hover:          $midnight-blue;
$color-link-visited:        $bouquet;

// Code
$color-code:                #333;
$color-code-background:     #f3f3f3;
$color-pre:                 #d4d4d4;
$color-pre-background:      #333;

// Selections
$color-selection:           #b3d4fc;

// Messages
$color-message:             #f4ecbb;
$color-success:             $boring-green;
$color-warning:             #cf8600;
$color-danger:           $orangey-red;
$color-notice:              #66b;
