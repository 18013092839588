.timeline-details {
	text-align: center;
	margin-bottom: 32px;
	.text {
		font-size: 14px;
		line-height: 20px;
		color: $color-primary;
		margin-bottom: 8px;
	}
	.status {
		font-size: 24px;
		line-height: 24px;
		font-weight: 700;
		color: $color-primary;
		text-transform: uppercase;
	}
}

.timeline-list {
	list-style-type: none;
	padding: 0;
	width: 70%;
	max-width: 70%;
	margin: 0 auto;
	.event {
		position: relative;
		display: block;
		min-height: 100px;
		margin-bottom: 8px;

		&::before {
			content: '';
			position: absolute;
			left: 179px;
			top: 32px;
			width: 4px;
			height: calc(100% - 32px);
			background: $blue-whale;
		}
		.date {
			position: absolute;
			width: 150px;
			left: 0;
			text-align: right;
			.datespan {
				font-size: 12px;
				line-height: 20px;
				font-weight: 700;
				margin: 0;
				color: $blue-whale;
			}
		}
		.status {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background: $color-primary;
			position: absolute;
			left: 170px;
			.fas {
				color: $white;
				width: 22px;
				line-height: 22px;
				text-align: center;
				font-size: 12px;
			}
		}
		.details {
			position: absolute;
			right: 0;
			width: calc(100% - 220px);
			.status-name {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 4px;
				font-weight: 700;
				color: $blue-whale;
				text-transform: uppercase;
			}
			.time {
				font-size: 12px;
				line-height: 16px;
				color: $blue-whale;
			}
		}
		&.on-going {
			&::before {
				background: $silver;
			}
			.status {
				background: $silver;
				.fas {
					display: none;
				}
			}
			.details {
				.status-name {
					color: $silver;
				}
			}
		}
		&.end {
			&::before {
				display: none;
			}
		}
	}
}