.main-gallery {
	width: 100%;
	overflow: hidden;
	height: 300px;
	.slick-arrow {
		z-index: 5;
		&.slick-prev {
			left: 25px;
		}
		&.slick-next {
			right: 25px;
		}
	}
	.product-item {
		padding-right: 8px;
	}
	.product-image {
		width: 350px;
		height: 300px;
		overflow: hidden;
		background: $bluey-grey;
		& > .image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}