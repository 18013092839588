	.icon-legend {
		padding: 6px;
		border-radius: 4px;
  	box-shadow: 0 3px 6px 0 rgba($black, 0.16);
		margin-right: 8px;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon {
			width: 18px;
			height: 18px;
		}
		&.waiting {
			background: $color-primary;
		}
		&.issue {
			background: $orangey-red;
		}

		&.small {
			width: 20px;
			height: 20px;
			&.icon {
				width: 14px;
				height: 14px;
			}
		}
	}

	.icon-legend-square {
		width: 12px;
		height: 12px;
		border-radius: 2px;
		background: $color-primary;
	}