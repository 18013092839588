	.other-details {
		border-bottom: 1px solid rgba($silver, 0.3);
		.details {
			margin: 0;
			.sub-title {
				font-size: 16px;
				font-weight: 900;
				line-height: 20px;
				color: $blue-whale;
				margin-bottom: 0;
			}
		}
	}