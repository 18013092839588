.disclaimer {
  background: lighten($color-danger, 35%);
  width: 100%;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  border: 2px solid $color-danger;
  border-radius: 10px;
  color: $color-danger;
  margin-bottom: 15px;
  text-align: center;
}