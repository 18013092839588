input[type="checkbox"].toggle-switch{
	font-size: 30px;
	appearance: none;
	width: 48px;
	height: 24px;
	background: $iron;
	border-radius: 50px;
	position: relative;
	cursor: pointer;
	outline: none;
	transition: all .2s ease-in-out;

	&:checked{
		background: $color-success;
  }

  &:after{
		position: absolute;
		content: "";
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: $white;
		box-shadow: 0 0 8px rgba(0,0,0,.3);
		transform: scale(.7);
		left: 0;
		transition: all .2s ease-in-out;
  }

  &:checked:after{
		left: calc(100% - 24px);
  }
}
