.form-control {
	&.form-select {
		&.filter {
			appearance: none;
			border: none;
			box-shadow: none;
			padding: 0 16px 0 0;
			font-size: 12px;
			font-weight: 900;
			line-height: 18px;
			width: auto;
			background: url('../../img/icon-dropdown.svg') no-repeat 98% 48%;
		}
	}
}

.form-label{
	&.filter {
		font-size: 12px;
		line-height: 18px;
	}
}

.form-search {
	position: relative;
	.form-control-icon {
		position: absolute;
		top: 4px;
		left: 8px;
		.icon {
				width: 16px;
				height: 16px;
		}
	}
	.form-control {
		padding: 10px 16px 10px 32px;
		font-size: 14px;
		line-height: 20px;
	}
}
.form-page-range {
	display: flex;
	align-items: center;
	.input-holder {
		width: 70px;
		margin: 0px 10px;
	}
}

.form-filter-keyword {
	display: flex;
	align-items: center;
	.input-holder {
		margin: 0px 10px;
	}
}