.status-pill {
  padding: 4px 10px;
  font-weight: 600;
  border-radius: 20px;
  color: $white;
  font-size: 12px;
  &.approved {
    background: $color-success;
  }
  &.waiting {
    background: $black;
  }
  &.processing {
    background: $tomato;
  }
  &.rejected {
    background: $color-danger;
  }
  &.pending {
    background: $gray-400;
  }
}