#root, #content, body {
  height: 100vh;
}


.monospace {
  font-family: monospace;
}

.box-fit-wrapper {
  position: relative;
  .box-fit-overflow {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0;
    left: 0;
    overflow: auto;
    will-change: transform;
  }
}

.dropdown-toggle > * {
  pointer-events: none; // make sure that the anchor is clicked instead of child elements
}

.with-arrow {
  position: relative;
  &::before,
  &::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }
  &::after {
    border-color: transparent;
    border-left-color: #fff;
    border-width: 15px;
    margin-top: -15px;
  }
  &::before {
    border-color: transparent;
    border-left-color: #dee2e6;
    // border-left-color: $border-color; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
    border-width: 16px;
    margin-top: -16px;
  }
}
::-webkit-scrollbar-track
{
  background-color: #e6e5e1;
  border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 6px;
  background-color: #e6e5e1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb
{
  background-color: #adb5bd;
  // background-color: $gray-500; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
  border-radius: 10px;
}

.detail-header-box {
  position: relative;
  min-height: 82px;
  .detail-header-action {
    position: absolute;
    top: 0;
    right: 0;
  }
  .detail-header-photo {
    position: relative;
    margin-bottom: -36px;
    min-height: 102px;
    max-height: 102px;
    min-width: 102px;
    max-width: 102px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 92px;
      max-width: 92px;
    }
  }
}

.detail-tabs {
  margin-top: -5px;
  & :first-child.nav-item {
    margin-left: 120px;
  }
}

table.table-fixed-header {
  position: relative;
  border-collapse: collapse;
  th, td {
    padding: 0.25rem;
  }
  th {
    background:#fff;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
}

.table-striped-column {
  tbody th:nth-of-type(even),
  tbody td:nth-of-type(even) {
    //background-color: $table-dark-accent-bg; PA FIX NITO THANKS, HINDI KO MA IMPORT YUNG VARIABLE
    background-color: rgba(0, 0, 0, 0.05);
  }
}

img.list-thumb {
  max-height: 48px;
  max-width: 48px;
}

.ReactVirtualized__Table__headerRow {
  background: #eaebef;
}

.ReactVirtualized__Table__Grid {
  outline: none;
}

.ReactVirtualized__Grid__innerScrollContainer {
  .ReactVirtualized__Table__row:nth-of-type(even) {
    background: rgba(0, 0, 0, 0.05);
  }
}

.modal-open .modal {
  will-change: transform;
}

#clipboard-input {
  opacity: 0;
  position: absolute;
  // border: 0;
  // height: 0;
  // width: 0;
}

.draft-editor-body {
  min-height: 300px;
  border: 1px solid #c6c6c6;
  border-radius: 0.25rem;
  padding-left: 10px;
  padding-right: 10px;
}

.spinner-bar {
  zoom:1;/* Increase this for a bigger symbole*/

  display: inline-block;

  width:12px;
  height:12px;

  animation: wait .80s steps(1, start) infinite;

  background: linear-gradient(0deg, #f4f5fa 1px, transparent 0, transparent 8px, #f4f5fa 8px),   /* 6  */
              linear-gradient(90deg, #f4f5fa 1px, #f6f9fb 0, #f6f9fb 3px, #f4f5fa 3px),

              linear-gradient(0deg, #ececf5 1px, transparent 0, transparent 8px, #ececf5 8px),   /* 5  */
              linear-gradient(90deg, #ececf5 1px, #f2f3f9 0, #f2f3f9 3px, #ececf5 3px),

              linear-gradient(0deg, #e7eaf4 1px, transparent 0, transparent 8px, #e7eaf4 8px),   /* 4  */
              linear-gradient(90deg, #e7eaf4 1px, #eef1f8 0, #eef1f8 3px, #e7eaf4 3px),

              linear-gradient(0deg, #b9bedd 1px, transparent 0, transparent 10px, #b9bedd 10px), /* 3  */
              linear-gradient(90deg, #b9bedd 1px, #d0d5e8 0, #d0d5e8 3px, #b9bedd 3px),

              linear-gradient(0deg, #9fa6d2 1px, transparent 0, transparent 15px, #9fa6d2 15px), /* 2  */
              linear-gradient(90deg, #9fa6d2 1px, #c0c5e1 0, #c0c5e1 3px, #9fa6d2 3px),

              linear-gradient(0deg, #8490c6 1px, transparent 0, transparent 15px, #8490c6 15px), /* 1  */
              linear-gradient(90deg, #8490c6 1px, #aeb5da 0, #aeb5da 3px, #8490c6 3px);

  background-repeat: no-repeat;

  background-size: 4px 9px,   /* 6 */
                   4px 9px,

                   4px 9px,   /* 5 */
                   4px 9px,

                   4px 9px,   /* 4 */
                   4px 9px,

                   4px 11px,  /* 3 */
                   4px 11px,

                   4px 16px,  /* 2 */
                   4px 16px,

                   4px 16px,  /* 1 */
                   4px 16px;

  background-position:-4px 3px, -4px 3px, -4px 3px, -4px 3px, -4px 3px, -4px 3px, -4px 2px, -4px 2px, -4px 0, -4px 0, -4px 0, -4px 0;

}

@keyframes wait{
  12.5%{
    background-position:   -4px,  /* 6 */
                           -4px,

                           -4px,  /* 5 */
                           -4px,

                           -4px,  /* 4 */
                           -4px,

                           -4px,  /* 3 */
                           -4px,

                           -4px,  /* 2 */
                           -4px,

                              0 ,  /* 1 */
                              0 ;
  }
  25%{
    background-position: -4px,  /* 6 */
                           -4px,

                           -4px,  /* 5 */
                           -4px,

                           -4px,  /* 4 */
                           -4px,

                           -4px,  /* 3 */
                           -4px,

                              0,  /* 2 */
                              0,

                            6px,  /* 1 */
                            6px;
  }
  37.5%{
    background-position: -4px,  /* 6 */
                           -4px,

                           -4px,  /* 5 */
                           -4px,

                           -4px,  /* 4 */
                           -4px,

                               0,  /* 3 */
                               0,

                             6px,  /* 2 */
                             6px,

                            12px,  /* 1 */
                            12px;
  }
  50%{
    background-position: -4px,  /* 6 */
                           -4px,

                           -4px,  /* 5 */
                           -4px,

                               0,  /* 4 */
                               0,

                             6px,  /* 3 */
                             6px,

                            12px,  /* 2 */
                            12px,

                            -4px,  /* 1 */
                            -4px;
  }
  62.5%{
    background-position: -4px,  /* 6 */
                           -4px,

                               0,  /* 5 */
                               0,

                             6px,  /* 4 */
                             6px,

                            12px,  /* 3 */
                            12px,

                            -4px,  /* 2 */
                            -4px,

                            -4px,  /* 1 */
                            -4px;
  }
  75%{
    background-position:     0,  /* 6 */
                               0,

                             6px,  /* 5 */
                             6px,

                            12px,  /* 4 */
                            12px,

                            -4px,  /* 3 */
                            -4px,

                            -4px,  /* 2 */
                            -4px,

                            -4px,  /* 1 */
                            -4px;
  }
  87.5%{
    background-position:   6px,  /* 6 */
                             6px,

                            12px,  /* 5 */
                            12px,

                            -4px,  /* 4 */
                            -4px,

                            -4px,  /* 3 */
                            -4px,

                            -4px,  /* 2 */
                            -4px,

                            -4px,  /* 1 */
                            -4px;
  }
  100%{
    background-position:    12px,  /* 6 */
                            12px,

                            -4px,  /* 5 */
                            -4px,

                            -4px,  /* 4 */
                            -4px,

                            -4px,  /* 3 */
                            -4px,

                            -4px,  /* 2 */
                            -4px,

                            -4px,  /* 1 */
                            -4px;
  }
}

.editable-label {
  .editable-action {
    opacity: 0;
    margin-left: 2px;
  }
  &:hover {
    .editable-action {
      opacity: 1;
    }
  }
}

textarea {
  will-change: transform;
  resize: none;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 0;

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 1;
    .label-on {
      position: absolute;
      top: 8px;
      z-index: 2;
      left: 4px;
      font-size: 12px;
    }
    .label-off {
      position: absolute;
      top: 8px;
      z-index: 2;
      right: 4px;
      font-size: 12px;
    }
  }

  .slider .toggle {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color:#fff;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 3;
    i {
      color: #a0a1ac;
      position: absolute;
      top: 4px;
      left: 6px;
    }
  }

  input {
    opacity: 0;
  }

  input + .slider {
    .label-on {
      opacity: 0;
    }
    .label-off {
      opacity: 1;
    }
  }

  input:checked + .slider {
    background-color: #2196F3;
    .label-on {
      opacity: 1;
    }
    .label-off {
      opacity: 0;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider .toggle {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round .toggle {
    border-radius: 50%;
  }
}

.action-hider {
  &:hover {
    .action {
      opacity: 1
    }
  }
  .action {
    opacity: 0;
  }
}

#my-modal-marker {
  position: relative;
  z-index: 1000;
}

#my-toast-marker {
  position: relative;
  z-index: 1001;
}

.login-account {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
  // background: url('../assets/img/bg.jpg') no-repeat center center, linear-gradient(180deg, #212938 0%, #0c121e 100%);
  background: #ed8822;
  background-size: cover;
	.login-form {
		max-width: 480px;
		width: 100%;
		margin:	auto;
		.login-header {
			text-align: center;
			.logo {
        max-width: 290px;
				margin-bottom: 40px;
			}
			.title {
				font-size: 24px;
				font-weight: 900;
				line-height: 60px;
				margin: 0;
				color: #fff;
			}
			.subtitle {
				font-size: 18px;
  			line-height: 24px;
				color: #fff;
				margin: 0;
			}
			margin-bottom: 48px;
		}
		.login-box {
			.login-input {
				background: #fff;
				padding: 6px 8px;
				border: 1px solid #808c99;
				border-radius: 8px;
				.form-label {
					font-size: 12px;
					line-height: 16px;
					margin: 0;
					text-transform: uppercase;
					font-weight: 600;
				}
				.form-control {
					padding: 0;
					line-height: 24px;
					font-size: 16px;
					height: 24px;
					border: none;
					appearance: none;
					background: #fff;
					outline: 0;
					box-shadow: none;
					&:hover,
					&:active,
					&:focus {
						background: #fff;
					}
				}
			}
		}
	}
}

.floating-form {
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	background: #ffffff;
	padding: 6px 8px;
}

.floating {
  transition: background-color 0.2s ease;
	outline: none;

	&:hover {
		.floating-input {
			border-color: #e3e3e3;
		}
	}

	&:hover,
	&:focus-within {
  	background-color: #ffffff;
	}
}

.floating-input {
  padding: 12px 8px 0;
  font-size: 16px;
  font-weight: 700;
	line-height: 32px;
	outline: none;

	&.form-control {
		height: 34px;
		box-shadow: none;
		border: 0;
		border-radius: 0;
	}

	&::placeholder {
		color: rgba(0, 0, 0, 0);
	}

	&:focus + .floating-label::after {
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}

	&:placeholder-shown + .floating-label::before {
		transform: translate3d(0, -32px, 0) scale3d(1, 1, 1);
		line-height: 32px;
	}

	&:focus + .floating-label::before {
		color: #808c99;
	}
}

.floating-label {
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
	margin: 0;

	&::before {
		color: #e3e3e3;
		font-weight: bold;
		content: attr(data-content);
		display: inline-block;
		filter: blur(0);
		backface-visibility: hidden;
		transform-origin: left top;
		transition: transform 0.2s ease;
		left: 0px;
		position: relative;
	}

	// &::after {
	// 	bottom: 1rem;
	// 	content: "";
	// 	height: 0.1rem;
	// 	position: absolute;
	// 	transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
	// 	opacity: 0;
	// 	left: 0;
	// 	top: 100%;
	// 	margin-top: -0.1rem;
	// 	transform: scale3d(0, 1, 1);
	// 	width: 100%;
	// 	// background-color: #808c99;
	// }
}

.floating-label::before,
.floating-input:focus + .floating-label::before {
  transform: translate3d(0, -38px, 0) scale3d(0.6, 0.6, 1);
	line-height: 12px;
}

.hidden.-visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}