@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}

@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		transform: translateX(0)
	}
	40% {
		transform: translateX(-12px)
	}
	60% {
		transform: translateX(-4px)
	}
}